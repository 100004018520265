var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "ma-3", attrs: { align: "left", justify: "left" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-3 font-weight-light white black--text" },
            [
              _c("v-icon", { staticClass: "primary--text lighten-2 pa-2" }, [
                _vm._v(" message ")
              ]),
              _vm._v("FAQ > Dúvidas frequentes ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ma-3 pa-3", attrs: { color: "primary" } },
        [
          _c(
            "v-row",
            { staticClass: "py-8" },
            [
              _c(
                "v-col",
                {
                  staticClass: "ma-3 pa-3",
                  attrs: { cols: "3", xs: "3", md: "3" }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "white--text ma-3 pa-3 mx-auto my-auto",
                      attrs: { size: "100", "elevation-8": "" }
                    },
                    [_vm._v(" quiz ")]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "ma-3 pa-3 mx-auto my-auto",
                  attrs: {
                    cols: "12",
                    sm: "12",
                    md: "5",
                    align: "left",
                    justify: "left"
                  }
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "white--text font-weight-black display-2" },
                    [_vm._v(" FAQ | Dúvidas Frequentes ")]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "ma-3 pa-3 mx-auto my-auto",
                  attrs: { sm: "12", md: "2", align: "left", justify: "left" }
                },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass:
                        "white--text font-weight-light subtitle mb-3 pa-3"
                    },
                    [
                      _vm._v(
                        " Nessa página você poderá encontrar a maior parte das dúvidas frequentes de uso do painel Recuperi. "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "v-card",
          { staticClass: "ma-3 pa-3" },
          [
            _c(
              "v-card-title",
              [
                _c("v-text-field", {
                  attrs: {
                    "append-icon": "mdi-magnify",
                    label:
                      "Digite aqui sua pesquisa por palavras chave, exemplo: Protesto",
                    "single-line": "",
                    solo: "",
                    "hide-details": ""
                  },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                })
              ],
              1
            ),
            _c("v-data-table", {
              attrs: {
                headers: _vm.headersTb1,
                items: _vm.dessertsTb1,
                "single-expand": _vm.singleExpand,
                expanded: _vm.expanded,
                "show-expand": "",
                "footer-props": {
                  itemsPerPageOptions: [10, 20, 100, -1],
                  itemsPerPageText: "Itens por página:"
                },
                "loading-text": "Pesquisando resultados...",
                "no-results-text": "Nenhum resultado encontrado...",
                "item-key": "name",
                search: _vm.search
              },
              on: {
                "update:expanded": function($event) {
                  _vm.expanded = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "" } },
                        [_c("v-toolbar-title", [_vm._v("Dúvidas Frequentes")])],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "expanded-item",
                  fn: function({ headers, item }) {
                    return [
                      _c(
                        "td",
                        {
                          staticClass: "pa-6",
                          attrs: { colspan: headers.length }
                        },
                        [_vm._v(" " + _vm._s(item.description) + " ")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      _c("v-card-text", { staticClass: "text-center" }, [
        _vm._v(
          "Tem duvidas que não foram encontradas aqui nesse painel? Entre em contato com nossa equipe de suporte, teremos o maior prazer em ajudar. "
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }